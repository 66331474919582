import AgoraRTM from "agora-rtm-sdk";
import state from "@/store";
import router from "../../router";

async function RTMClient(appid, uid, token) {
  const result = await RTMService.getInstace();
  return result;
}

class RTMChannelService {
  static __channel = null;

  static getInstace() {
    return new Promise((resolve, reject) => {
      if (!RTMChannelService.__channel) {
        // 从全局单例中拿rtm
        RTMService.getInstace().then((res) => {
          const rtm = res;
          const { rtmChannel } = state.getters.getUserInfo;
          RTMChannelService.__channel = rtm.createChannel(rtmChannel);
          RTMChannelService.__channel
            .join()
            .then(() => {
              console.log("--------------channel加入成功----------------");
              resolve(RTMChannelService.__channel);
            })
            .catch((err) => {
              reject(err);
            });
        });
      } else {
        resolve(RTMChannelService.__channel);
      }
    });
  }
}

class RTMService {
  static __rtm = null;
  static _isLogin = false;
  static _onLoginSuccess = null;

  static async getInstace() {
    return new Promise((resolve, reject) => {
      if (!RTMService.__rtm) {
        if (!state.getters.getUserInfo) {
          // 强制登出
          router.replace("/login");
          try {
            // 登出声网rtm
            window.GLOBAL.rtmCommonClient.logout();
            // 离开频道
            window.GLOBAL.rtmCommonChannel.leave();
          } catch (e) {
            console.log(e);
          }
          throw Error("用户信息未初始化");
        }

        const { appId, rtmUid, rtmToken } = state.getters.getUserInfo;
        const client = AgoraRTM.createInstance(appId);

        client.on("ConnectionStateChanged", function (state, reason) {
          if (state === "CONNECTED" && reason === "LOGIN_SUCCESS") {
            RTMService._isLogin = true;
            // 登录回调
            if (RTMService._onLoginSuccess) {
              RTMService._onLoginSuccess();
            }
          }
        });

        // 登录
        client
          .login({
            uid: rtmUid.toString(),
            token: rtmToken,
          })
          .then(() => {
            console.log(`RTM 登陆成功------`);
            resolve(client);
          })
          .catch((err) => {
            console.log(`RTM 登陆失败------：${err}`);
            reject();
          });
        RTMService.__rtm = client;
      } else {
        // 已有实例，判断是否登录
        if (RTMService._isLogin) {
          resolve(RTMService.__rtm);
        } else {
          RTMService._onLoginSuccess = () => {
            resolve(RTMService.__rtm);
          };
        }
      }
    });
  }

  static async createRTM(appid, uid, token) {
    return new Promise((resolve, reject) => {
      // debugger
      const client = AgoraRTM.createInstance(appid);
      // client.on('ConnectionStateChanged',function(state,reason){
      //   console.log('ConnectionStateChanged',state,reason)
      // })
      resolve(client);
      // 登录
      // client.login({
      //   uid: uid.toString(),
      //   token: token
      // }).then(() => {
      //   console.log(`登陆成功----`)
      //   resolve(client)
      // }).catch((err)=>{
      //   console.log(`登陆失败------：${err}`)
      //   reject()
      // })
    });
  }
}

export { RTMChannelService, RTMClient, RTMService };
export default RTMClient;
